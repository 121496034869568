// stylelint-disable declaration-no-important
//
// RTL Support
//
*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;

  body {
    text-align: right;
  }

  // Dropdown
  .dropdown-item {
    text-align: right;

    i {
      margin-right: -10px;
      margin-left: 10px;
    }

    .badge {
      right: auto;
      left: 10px;
    }
  }

  // Float
  .float-left {
    float: right !important;
  }
  .float-right {
    float: left !important;
  }

  // Margin and Padding
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {
          .#{$abbrev}r#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: 0 !important;
            #{$prop}-left: $length !important;
          }
          .#{$abbrev}l#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: $length !important;
            #{$prop}-left: 0 !important;
          }
        }
      }

      // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
      @each $size, $length in $spacers {
        @if $size != 0 {
          .mr#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-right: 0 !important;
            margin-left: -$length !important;
          }
          .ml#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-right: -$length !important;
            margin-left: 0 !important;
          }
        }
      }

      // Some special margin utils
      .mr#{$infix}-auto,
      .mx#{$infix}-auto {
        margin-left: auto !important;
      }
      .ml#{$infix}-auto,
      .mx#{$infix}-auto {
        margin-right: auto !important;
      }
    }
  }

}
