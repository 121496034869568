// stylelint-disable selector-no-qualifying-type

*[dir="rtl"] {
  .btn-group {
    // Prevent double borders when buttons are next to each other
    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
      margin-right: -$btn-border-width;
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      @include border-radius($border-radius, 0);
      @include border-left-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-radius($border-radius, 0);
      @include border-right-radius(0);
    }

    > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
      @include border-left-radius(0);
      @include border-right-radius(0);
    }
  }
}
